/*   
Por Camila Lins
http://camilalins.co
2023
*/

@charset "utf-8";

/* Reset */
*, *:before, *:after {box-sizing:border-box; -moz-box-sizing:border-box;}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, a, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {margin:0; padding:0; border:0; outline:0; font-size:100%; vertical-align:baseline; background:transparent;}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {display:block;}
audio, canvas, progress, video {display:inline-block;}
img, object, embed, video, iframe {max-width:100%;}
ul {list-style:none;}
blockquote, q {quotes:none;}
blockquote:before, blockquote:after, q:before, q:after {content:''; content:none;}
del {text-decoration:line-through;}
abbr[title], dfn[title] {border-bottom:1px dotted #000; cursor:help;}
table {border-collapse:collapse; border-spacing:0; font-size:inherit; font:100%;}
th {font-weight:bold; vertical-align:bottom;}
td {font-weight:normal; vertical-align:middle;}
hr {display:block; height:1px; border:0; border-top:1px solid #CCC; margin:10px 0; padding:0;}
input, select {vertical-align:middle;}
pre {white-space:pre; white-space:pre-wrap; white-space:pre-line; word-wrap:break-word;}
input[type="radio"] {vertical-align:text-bottom;}
input[type="checkbox"] {vertical-align:bottom;}
input:focus, textarea:focus, button:focus {outline:0;}
a:hover, a:active {outline:0;}
small {font-size:85%;}
strong, th {font-weight:bold;}
td, td img {vertical-align:top;}
sub, sup {font-size:75%; line-height:0; position:relative;}
sup {top:-0.5em;}
sub {bottom:-0.25em;}
pre, code, kbd, samp {font-family:monospace, sans-serif;}
label, input[type=button], input[type=submit], button {cursor:pointer;}
body, select, input, textarea {-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
button, input, select, textarea {margin:0; resize:none; color:inherit; font:inherit; border:0; border-radius:0; -webkit-appearance:none; -moz-appearance:none; appearance:none;}
button {width:auto; overflow:visible;}
textarea {overflow:auto;}
ins {background:#FDD900; color:#000; text-decoration:none;}
mark {background:#FDD900; color:#000; font-style:italic; font-weight:bold;}
audio, canvas, iframe, img, svg, video {vertical-align:middle;}
html {font-family:sans-serif; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%;}

::-moz-selection{background:#FFF; color:#000; text-shadow:none;}
::selection {background:#FFF; color:#000; text-shadow:none;}

::-webkit-input-placeholder {color:#CCC; opacity:1;}
::-moz-placeholder {color:#CCC; opacity:1;}
::placeholder {color:#CCC; opacity:1;}

/* Toolbox */
.hide {display:none !important;}
.flex {display:flex;}

/* Variáveis */
:root {}

/* Page style */
html {overscroll-behavior:none; overflow-x:hidden;}
body {font:18px 'Sometype Mono', sans-serif; color:#FFF; background:#000000; min-height:100vh; overflow-x:hidden; letter-spacing:1px;}

a {color:var(--cor-verde); text-decoration:none; -webkit-tap-highlight-color:transparent; transition:all 150ms ease-out;}
a:hover {color:#FFF;}

.mobile-check {display:none;}


h1 {position:fixed; top:5%; left:4%; font-size:20px; line-height:30px;}
h1 span {display:block;}
h2 {position:fixed; bottom:5%; right:4%;}
h3 {position:fixed; top:50%; left:50%; transform:translate(-50%, -50%); font-size:30px; background:#FFF; color:#000;}

.webgl {position:fixed; top:0; left:0; outline:none; z-index:-1;}

#progress {background:#FFF; height:6px; width:10%; transition:all 150ms ease-out;}

@media screen and (max-width:850px){
    h3 {font-size:5vw; transform:translateY(-50%); left:0; right:0; text-align:center;}
}